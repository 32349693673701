<template>
  <div class="p-2 flex flex-row justify-around w-full mt-10">
    
    <div class="p-2 w-full">
      <h6 class="mb-4">List A</h6>
      <span>{{ list_a_size }}</span>
      <textarea class="w-full p-2 text-gray-600 h-36-rem border border-gray-400 rounded-md bg-blue-300" v-model="list_a"></textarea>
    </div>
    <div class="p-2 w-full">
      <h6 class="mb-4">Only in A</h6>
      <span>{{ only_in_a_size }}</span>
      <textarea class="w-full p-2 text-gray-600 h-36-rem border border-gray-400 rounded-md bg-blue-100" v-model="only_in_a"></textarea>
    </div>
    
    <div class="p-2 w-full">
      <h6 class="mb-4">In A & B</h6>
      <span>{{ in_a_and_b_size }}</span>
      <textarea class="w-full p-2 text-gray-600 h-36-rem border border-gray-400 rounded-md bg-red-300" v-model="in_a_and_b"></textarea>
    </div>

    <div class="p-2 w-full">
      <h6 class="mb-4">Only in B</h6>
      <span>{{ only_in_b_size }}</span>
      <textarea class="w-full p-2 text-gray-600 h-36-rem border border-gray-400 rounded-md bg-green-100" v-model="only_in_b"></textarea>
    </div>
    <div class="p-2 w-full">
      <h6 class="mb-4">List B</h6>
      <span>{{ list_b_size }}</span>
      <textarea class="w-full p-2 text-gray-600 h-36-rem border border-gray-400 rounded-md bg-green-300" v-model="list_b"></textarea>
    </div>
  </div>

  <div class="w-full">
    <span class="w-auto py-2 px-8 bg-gray-300 text-white rounded-md cursor-pointer" @click="process">Press me to Process</span>
  </div>
</template>

<script>
export default {
    name: 'Komparator',
        data () {
            return {
              list_a:'',
              list_a_size:0,
              only_in_a:'',
              only_in_a_size:0,
              in_a_and_b:'',
              in_a_and_b_size:0,
              only_in_b:'',
              only_in_b_size:0,
              list_b:'',
              list_b_size:0,
            }
        },
        methods: {
            process() {

                // Set all to UpperCase
                this.list_a = this.list_a.toUpperCase();
                // Split List A
                let splitListA = this.list_a.split("\n")
                // Clear Empty elemenst, order and remove duplicates
                splitListA = this.clearList(splitListA)
                // Return to list clean content
                this.list_a = splitListA.join("\n")
                // Set list size
                this.list_a_size = splitListA.length
                                
                // Set all to UpperCase
                this.list_b = this.list_b.toUpperCase();
                // Split List B
                let splitListB = this.list_b.split("\n");
                // Clear Empty elemenst, order and remove duplicates
                splitListB = this.clearList(splitListB)
                // Return to list clean content
                this.list_b = splitListB.join("\n")
                // Set list size
                this.list_b_size = splitListB.length
                                
                this.calculateOnlyInA(splitListA,splitListB)
                this.calculateOnlyInB(splitListA,splitListB)
                this.calculateInAAndB(splitListA,splitListB)
            
            },
            clearList(list){
                // Remove Empty Strings
                list.forEach( function(element, index) {
                    if (element == '') {
                        list.splice(index, 1);
                    }
                });
                // Sort
                list = list.sort()
                // Remove Duplicates
                list = [...new Set(list)]
                //Return List
                return list
            },
            calculateOnlyInA(a,b){
                let result = a.filter(x => b.indexOf(x) === -1)
                result = result.sort()
                this.only_in_a_size = result.length
                this.only_in_a = result.join("\n")
            },
            calculateOnlyInB(a,b){
                let result =  b.filter(x => a.indexOf(x) === -1)
                result = result.sort()
                this.only_in_b_size = result.length
                this.only_in_b = result.join("\n")
            },
            calculateInAAndB(a,b){
                let result =  a.filter(element => b.includes(element));
                result = result.sort()
                this.in_a_and_b_size = result.length
                this.in_a_and_b = result.join("\n")
            }

        }
}
</script>

<style scoped>
.h-36-rem{
    height: 36rem;
}
</style>